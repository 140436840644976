import {
  Alert,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Grid,
  Loader,
  Text,
} from "@mantine/core";
import { Fragment, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { API_CONFIG } from "../../../constant/constant";
import httpService from "../../../shared/services/http.service";
import { connectSocket } from "./socketservice";
import Logo from "../../../assets/images/logo.png";
import { Image } from "@mantine/core";
import DOWNLOAD from "../../../assets/images/download.png";
import CHOOSE from "../../../assets/images/choose.png";
import SCAN from "../../../assets/images/scan.png";
import GOOGLEPLAY from "../../../assets/images/googlePlay.png";
import APPSTORE from "../../../assets/images/appStore.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../../src/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
  getAuth,
} from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { dashboardLogin, generateOTP, verifyOTP } from "../../users/services/services";
import WorkspaceList from "../../workspaces/container/workspaceLists";
import CustomOtpInput from "./otpBox";
import { debounce } from "lodash";
import useSaveParams from "../../../hooks/useSaveParams";
import useManageParameters from "../../../hooks/useManageParameters";
import { verify } from "crypto";

interface CustomWindow extends Window {
  recaptchaVerifier?: RecaptchaVerifier;
  confirmationResult?: ConfirmationResult;
}

declare let window: CustomWindow | undefined;
if (typeof window === "undefined") {
  window = undefined;
}

const Home = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [sessionId, setSessionId] = useState("");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [userIdToken, setUserIdToken] = useState(null);
  // const [workspaces, setWorkspaces] = useState([]);
  // const [authorizationToken, setAuthorizationToken] = useState(null);
  const [otpTimeout, setOtpTimeout] = useState(120000);
  const [isResendAllowed, setIsResendAllowed] = useState(true);
  const [resendTimer, setResendTimer] = useState(120);
  const [resendTimerInterval, setResendTimerInterval] = useState(null);
  const [onInvalidOtp, setOnInvalidOtp] = useState(false);

  useSaveParams();
  const { authToken, workspace_id } = useManageParameters();

  const country_code = phone.substring(0, 2);
  const mobile_no = phone.substring(2);
  let recaptchaVerifier;

  const showToast = debounce((message) => {
    toast.error(message);
  }, 1000);

  function handleVerifyOtp() {
    setOnInvalidOtp(false);
    setLoading(true);
    // window.confirmationResult
    //   .confirm(otp)
    verifyOTP({ mobile_no: mobile_no,country_code:country_code, otp: otp })
      .then( (res) => {
        if(!res['success'])
        {//console.log('invalid otp')
          showToast("Invalid OTP. Please enter a valid OTP.");
          setOnInvalidOtp(true);
          setLoading(false);
          return;
        }
        const idTokenResult = res['id_token'];//await res.user.getIdToken();
        setUserIdToken(idTokenResult);
        clearTimeout(otpTimeout);
        setOtpTimeout(120000);
        if (idTokenResult && sessionId) {
          const queryParams = {
            session_id: sessionId,
            mobile_no: mobile_no,
            country_code: country_code,
            id_token: idTokenResult,
          };
          dashboardLogin(queryParams)
            .then((response: any) => {
              localStorage.setItem("current_user", JSON.stringify(response));
              const { auth_token } = response;
              localStorage.setItem("authToken", auth_token);
              // setAuthorizationToken(auth_token);
              if (auth_token) {
                httpService
                  .get(
                    "dashboard/workspaces",
                    {},
                    { Authorization: auth_token }
                  )
                  .then((response: any) => {
                    // setWorkspaces(response?.workspaces);
                    navigate("/workspaces", {
                      state: {
                        workspaces: response.workspaces,
                        auth_token: auth_token,
                      },
                    });
                  })
                  .catch((error) => {
                    console.error("Error in second API:", error);
                    showToast("Something went wrong with server");
                  })
                  .finally(resetValues);
              }
            })
            .catch((error) => {
              console.error("Error in dashboardLogin:", error);
              showToast("Mobile number is not a user in talker");
              resetValues();
              setUserIdToken(null);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "auth/invalid-verification-code") {
          showToast("Invalid OTP. Please enter a valid OTP.");
          setOnInvalidOtp(true);
        } else {
          showToast(err.message);
          resetValues();
        }
        setUserIdToken(null);
      });
  }

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            //   onSignUp();
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
          },
        }
      );
    }
  }

  const onSignUp = debounce(async () => {
    console.log('calling the function signup')
    if (isResendAllowed) {
      console.log('entered the function signup for')
      setOtp("");
      setLoading(true);
      await onCaptchVerify();
      const appVerifier = window.recaptchaVerifier;
      const formatPhone = "+" + phone;
      generateOTP({ mobile_no: mobile_no,country_code:country_code }).then((res) => {
      // signInWithPhoneNumber(auth, formatPhone, appVerifier)
      //   .then((confirmationResult) => {
      //     window.confirmationResult = confirmationResult;
     //console.log(res);

          setLoading(false);
          setShowOtp(true);
          clearTimeout(otpTimeout);
          setOtpTimeout(120000);
          startResendTimer();
          const urlParams = new URLSearchParams(window.location.search);
          const mParam = urlParams.get("m");
          if (mParam === "i_a") {
            localStorage.setItem("mParam", mParam);
          }
        })
        .catch((error) => {
          console.log("Error sending OTP:", error.message);
          showToast(`Error: ${error.message}Please try after sometime`);
          setLoading(false);
          setShowOtp(false);
          setPhone("");
          clearInterval(resendTimerInterval);
          setResendTimer(120);
        });
    } else {
      showToast("Resend OTP is allowed after 120 seconds only.");
    }
  }, 1000);
    
  

  function resetValues() {
    setLoading(false);
    setShowOtp(false);
    setPhone("");
    setOtp("");
    if (recaptchaVerifier) {
      recaptchaVerifier.clear();
    }
    setIsResendAllowed(true);
    clearInterval(resendTimerInterval);
    setResendTimer(120);
  }

  const startResendTimer = () => {
    setIsResendAllowed(false);
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(timer);
          setIsResendAllowed(true);
          return 120;
        }
        return prevTimer - 1;
      });
    }, 1000);
    setResendTimerInterval(timer);
  };

  useEffect(() => {
    if (authToken && workspace_id) {
      navigate(`/workspaces/dashboard/users`);
    } else {
      localStorage.clear();
      if (sessionId === "") {
        getSession();
      }
    }
  }, [sessionId]);

  const getSession = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const mParam = urlParams.get("m");
    httpService
      .post(
        API_CONFIG.path.createSession,
        {},
        {},
        { Authorization: API_CONFIG.globalAuth }
      )
      .then(({ session_id }) => {
        setIsLoading(false);
        setSessionId(session_id);
        const socket = connectSocket({ session_token: `${session_id}` });
        socket.on("session_start", (data) => {
          localStorage.setItem("authToken", JSON.parse(data).auth_token);
          if (mParam === "i_a") {
            localStorage.setItem("mParam", mParam);
          }
          localStorage.setItem("workspace_id", JSON.parse(data).workspace_id);
          localStorage.setItem("current_user", data);
          socket.emit("session_confirmation", {
            auth_token: JSON.parse(data).auth_token,
          });

          navigate(`/workspaces/dashboard/users`);
        });
        socket.on("connect_error", (data) => {
          console.log("error:", data);
        });
      })

      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  const DividerWithText = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Divider style={{ width: "50%" }} />
        <div style={{ margin: "0 10px" }}>OR</div>
        <Divider style={{ width: "50%" }} />
      </div>
    );
  };

  const handleCancel = () => {
    clearInterval(resendTimerInterval);
    setResendTimer(120);
    setUserIdToken(null);
    setShowOtp(false);
    setPhone("");
    if (recaptchaVerifier) {
      recaptchaVerifier.clear();
    }
    setIsResendAllowed(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowOtp(false);
      setPhone("");
      setOtp("");
      setLoading(false);
    }, otpTimeout);

    return () => clearTimeout(timeoutId);
  }, [otpTimeout]);

  if (isLoading)
    return (
      <>
        <Card
          shadow="sm"
          p="lg"
          my={"xs"}
          radius="md"
          withBorder
          style={{ textAlign: "left" }}
        >
          <div className="flex align-center">
            <img src={Logo} height={60} style={{ marginTop: "2px" }} />
            <h3>Talker Work</h3>
          </div>
        </Card>
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader variant="bars" />
        </div>
      </>
    );

  return (
    <Box>
      <Card
        shadow="sm"
        p="lg"
        my={"xs"}
        radius="md"
        withBorder
        style={{ textAlign: "left" }}
      >
        <div className="flex align-center">
          <img src={Logo} height={60} style={{ marginTop: "2px" }} />
          <h3>Talker Work</h3>
        </div>
      </Card>
      <Card
        shadow="sm"
        p="lg"
        my={"xs"}
        radius="md"
        withBorder
        style={{ textAlign: "left" }}
      >
        <Grid style={{ height: "100%" }} mt="0px" align={"center"}>
          <Center
            style={{
              width: "100%",
              height: "100%",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <Toaster toastOptions={{ duration: 3000 }} />
            <h2 className="hide-in-mobile">Welcome To Talker.Network</h2>
            <h2 className="show-in-mobile-only">Welcome To</h2>
            <h2 className="show-in-mobile-only">Talker.Network</h2>
            <Box id="recaptcha-container"></Box>
            {userIdToken == null ? (
              <>
                <Text
                  size={"xl"}
                  fw={400}
                  color="#667085"
                  style={{ marginBottom: "10px" }}
                >
                  Login to your account
                </Text>
                {showOtp ? (
                  <>
                    <CustomOtpInput
                      length={6}
                      onChange={setOtp}
                      onInvalidOtp={onInvalidOtp}
                    />
                    <Box className="mobile-no-box">
                      <Text size={"sm"} fw={400} color="#667085" mr={8}>
                        Didn&apos;t get a code?
                      </Text>
                      <Button
                        style={{
                          background: "none",
                          cursor: isResendAllowed ? "pointer" : "",
                        }}
                        disabled={!isResendAllowed}
                      >
                        <Text
                          size={"sm"}
                          fw={400}
                          color="#667085"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (isResendAllowed) {
                              onSignUp();
                            } else {
                              showToast(
                                "Resend OTP is allowed after 120 seconds only."
                              );
                            }
                          }}
                        >
                          Click to resend{" "}
                          {isResendAllowed
                            ? ""
                            : `after (${resendTimer} seconds)`}
                        </Text>
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={handleVerifyOtp}
                        className="hide-in-mobile"
                        style={{
                          backgroundColor: "#75B121",
                          width: "150px",
                          marginTop: "10px",
                          marginBottom: "50px",
                        }}
                      >
                        Verify the code
                      </Button>
                      <Button
                        onClick={handleVerifyOtp}
                        className="show-in-mobile-only"
                        style={{
                          backgroundColor: "#75B121",
                          width: "150px",
                          marginTop: "10px",
                          marginBottom: "50px",
                        }}
                      >
                        Verify
                      </Button>
                      <Button
                        onClick={handleCancel}
                        style={{
                          backgroundColor: "#818181",
                          width: "150px",
                          marginTop: "10px",
                          marginBottom: "50px",
                          marginLeft: "20px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={{ width: "300px", marginTop: "10px" }}>
                      <Text className="hide-in-mobile">Phone number</Text>
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={setPhone}
                      />
                    </Box>
                    <Button
                      onClick={onSignUp}
                      className="hide-in-mobile"
                      style={{
                        backgroundColor: "#75B121",
                        width: "300px",
                        marginTop: "10px",
                        marginBottom: "50px",
                      }}
                      disabled={loading}
                    >
                      {loading && (
                        <Loader
                          variant="bars"
                          color="white"
                          size={"sm"}
                          mr={10}
                        />
                      )}
                      Sign In
                    </Button>
                    <Button
                      onClick={onSignUp}
                      className="show-in-mobile-only"
                      style={{
                        backgroundColor: "#75B121",
                        width: "300px",
                        marginTop: "10px",
                        marginBottom: "50px",
                      }}
                    >
                      {loading && (
                        <Loader
                          variant="bars"
                          color="white"
                          size={"sm"}
                          mr={10}
                        />
                      )}
                      Log In
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Loader variant="bars" />
                ) : (
                  <>{/* <WorkspaceList/> */}</>
                )}
              </>
            )}
          </Center>
        </Grid>
        {userIdToken === null ? (
          <>
            <DividerWithText />
            <Grid style={{ height: "100%" }} mt="0px" align={"center"}>
              <Grid.Col md={6} lg={6}>
                <Center
                  style={{
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Loader variant="bars" />
                  ) : (
                    <Fragment>
                      {sessionId ? (
                        <QRCode
                          size={150}
                          style={{
                            height: "150px",
                            width: "150px",
                          }}
                          value={sessionId}
                          viewBox={`0 0 150 150`}
                        />
                      ) : (
                        <Alert
                          icon={<i className="bx bx-error-circle" />}
                          title="Something went wrong!"
                          color="red"
                          radius={"md"}
                        >
                          <Text weight={"bold"}>
                            Please try again by refreshing page.
                          </Text>
                        </Alert>
                      )}
                    </Fragment>
                  )}
                </Center>
              </Grid.Col>
              <Card
                shadow="md"
                p="lg"
                my={"xs"}
                radius="md"
                withBorder
                style={{ marginTop: "20px" }}
              >
                <Box className="qr-scanning">
                  <Box className="qr-image">
                    <Image src={DOWNLOAD} width={18} height={22} />
                  </Box>
                  <Box className="download-details">
                    <Text size={"xl"} fw={500} color="#75B121">
                      Download The App
                    </Text>
                    <Text>
                      Download Talker PTT Walkie Talkie app from google play
                      store or app store and login using your mobile number.
                    </Text>
                  </Box>
                </Box>
                <Box className="qr-scanning">
                  <Box className="qr-image">
                    <Image src={CHOOSE} width={20} height={18} />
                  </Box>
                  <Box className="download-details">
                    <Text size={"xl"} fw={500} color="#75B121">
                      Choose Your Workspace
                    </Text>
                    <Text>
                      Choose Your workspace and click on Admin button on top bar
                      of the app.
                    </Text>
                  </Box>
                </Box>
                <Box className="qr-scanning">
                  <Box className="qr-image">
                    <Image src={SCAN} width={20} height={20} />
                  </Box>
                  <Box className="download-details">
                    <Text size={"xl"} fw={500} color="#75B121">
                      Scan Your QR Code
                    </Text>
                    <Text>
                      Select the scan qr code and scan the qr code on the left
                      to log into the talker work dashboard.
                    </Text>
                  </Box>
                </Box>
                <Box className="store">
                  <Image src={GOOGLEPLAY} width={130} height={40} />
                  <Image src={APPSTORE} width={110} height={40} />
                </Box>
              </Card>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Card>
    </Box>
  );
};

export default Home;
