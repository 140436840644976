import { AxiosResponse } from "axios";
import { API_CONFIG } from "../../../constant/constant";
import { GlobalObjectInterface } from "../../../shared/interface";
import HttpService from "../../../shared/services/http.service";
export const generateOTP = (params: any) => {
return new Promise((resolve, reject) => {
  HttpService.get(API_CONFIG.path.generateOTP,params,{Authorization:API_CONFIG.globalV1Auth})
    .then((data: GlobalObjectInterface) => {
        
      resolve(data);
    })
    .catch((error) => {
      console.log("Error : ", error);
      reject(error);
    });
  });
}
export const verifyOTP = (params: any ) => {
  return new Promise((resolve, reject) => {
    HttpService.post(API_CONFIG.path.generateOTP,  params ,{},{Authorization:API_CONFIG.globalV1Auth,contentType: "multipart/form-data",
    })
      .then((data: GlobalObjectInterface) => {
       
          resolve(data);
        
        
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
    });
  }

export const fetchUserList = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.user, params)
      .then((data: GlobalObjectInterface) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const fetchSubscriptions = () => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.fetchSubscriptions)
      .then(( data : GlobalObjectInterface) => {
        console.log("data",data)
        resolve(data);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const fetchSubscriptionCoupons = (plan_id: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.fetchSubscriptionCoupons, {plan_id})
      .then(( coupons : GlobalObjectInterface) => {
        resolve(coupons);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const cancelSubscriptionPlans = (workspace_id: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("workspace_id", workspace_id);
    HttpService.post(API_CONFIG.path.cancelSubscriptions, formData,{}, {
        contentType: "multipart/form-data",
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const updateBilling = (workspace_id: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.updateBilling, {workspace_id})
      .then(( update : GlobalObjectInterface) => {
        resolve(update);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const generatePayLink = (workspace_id: any,plan_id:any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.generatePaymentLink, {workspace_id,plan_id})
      .then(( paylink : GlobalObjectInterface) => {
        resolve(paylink);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const fetchSpecificWorkspace = (workspace_id: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.fetchWorkSpaces, {workspace_id})
      .then(( workspace : GlobalObjectInterface) => {
        resolve(workspace);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const changePlans = (workspace_id: string,plan_id:string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("workspace_id", workspace_id);
    formData.append("plan_id", plan_id);
    HttpService.post(API_CONFIG.path.changePlan, formData,{}, {
        contentType: "multipart/form-data",
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const inviteUser = (queryParams: any) => {
  return new Promise((resolve, reject) => {
    HttpService.post(API_CONFIG.path.user, {}, queryParams, {
        contentType: "multipart/form-data",
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const inviteLink = (
  formData: FormData,
  queryParams: { workspace_id: string; emails: any[] }
) => {
  return new Promise((resolve, reject) => {
    HttpService.post(API_CONFIG.path.inviteLink, formData,queryParams,{contentType:"multipart/form-data"})
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error: ", error);
        reject(error);
      });
  });
};

export const updateInviteLink = (formData: FormData) => {
  return new Promise((resolve, reject) => {
    HttpService.put(API_CONFIG.path.inviteLink, formData, {}, {
      contentType: "multipart/form-data",
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const dashboardLogin = (queryParams: any) => {
  return new Promise((resolve, reject) => {
    HttpService.post(API_CONFIG.path.dashboardLogin, {}, queryParams)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const deleteUser = (queryParams: any) => {
  return new Promise((resolve, reject) => {
    HttpService.deleteRequest(API_CONFIG.path.user, {}, queryParams)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const updateUser = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.put(API_CONFIG.path.user, params, {}, { contentType: "" })
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
